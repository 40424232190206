
import {
  CLEAR,
  TOKEN,
  SET,
  CURRENTROUTE,
  TABLEHEADER,
  PACKAGELIST,
  PACKAGEDETAIL,
  CRUISEDETAIL,
  CONTACTFORM,
  BLOGDETAIL,
  JOBS,
  SEARCH
} from "./types";

const initialState = {
  token: null,
  currentRoute: null,
  tableHeader: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET:
      return { ...state, sidebarShow: action.payload };

    case TOKEN:
      return { ...state, token: action.payload };

    case CURRENTROUTE:
      return { ...state, currentRoute: action.payload };

    case TABLEHEADER:
      return { ...state, tableHeader: action.payload };

    case PACKAGELIST:
      return { ...state, packageList: action.payload };

    case PACKAGEDETAIL:
      return { ...state, packageDetail: action.payload };


    case CRUISEDETAIL:
      return { ...state, cruiseDetail: action.payload };

    case CONTACTFORM:
      return { ...state, contactFrom: action.payload };

    case BLOGDETAIL:
      return { ...state, blogDetail: action.payload };

    case JOBS:
      return { ...state, Jobs: action.payload };

    case SEARCH:
      return { ...state, Search: action.payload };

    case CLEAR: {
      return initialState;
    }
  }

  return state;
};
export default reducer;
