import "./App.css";
import { Routes, Route } from "react-router-dom"; // No need to import BrowserRouter here
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Suspense } from "react";
import Loader from "./Containers/LoaderSpinner";
import React from "react";

// Lazy loaded components
const Home = React.lazy(() => import("./Containers/Home"));
const Cruise = React.lazy(() => import("./Containers/Cruise"));
const Blog = React.lazy(() => import("./Containers/Blog"));
const Career = React.lazy(() => import("./Containers/Career"));
const PrivacyAndPolicy = React.lazy(() => import("./Containers/PrivacyAndPolicy"));
const About = React.lazy(() => import("./Containers/About"));
const Mice = React.lazy(() => import("./Containers/Mice"));
const BlogDetail = React.lazy(() => import("./Containers/BlogDetail"));
const Inbound = React.lazy(() => import("./Containers/Inbound"));
const ContactUs = React.lazy(() => import("./Containers/ContactUs"));
const DetailedPackage = React.lazy(() => import("./Containers/DetailedPackage"));
const Payment = React.lazy(() => import("./Containers/Payment"));
const GeneratePdf = React.lazy(() => import("./Containers/GeneratePdf"));
const PageNotFound = React.lazy(() => import("./Containers/PageNotFound"));
const PrivacyPolicy = React.lazy(() => import("./Containers/PrivacyPolicy"));
const TermsAndConditions = React.lazy(() => import("./Containers/Terms&Conditions"));
const DestinationWedding = React.lazy(() => import("./Containers/DestinationWeddings"));
const PaymentStatus = React.lazy(() => import("./Containers/PaymentStatus"));
const FormSubmission = React.lazy(() => import("./Components/FormSubmission"));

function App() {
  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/package/:pageurl/:tourId/:pagename" element={<DetailedPackage />} />
          <Route path="/package/:pageurl/:destinationId" element={<Inbound />} />
          <Route path="/cruise" element={<Cruise />} />
          <Route path="/blog/:blogId/:pageurl" element={<BlogDetail />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/career" element={<Career />} />
          <Route path="/privacy-and-policy" element={<PrivacyAndPolicy />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/mice" element={<Mice />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/payment-status" element={<PaymentStatus />} />
          <Route path="/pdf/:pageId" element={<GeneratePdf />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/privacyandpolicy" element={<PrivacyPolicy />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/destination-wedding" element={<DestinationWedding />} />
          <Route path="/submit" element={<FormSubmission />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
