export const SET = "SET";
export const TOKEN = "TOKEN";
export const CURRENTROUTE = "CURRENTROUTE";
export const TABLEHEADER = "TABLEHEADER";
export const CONTACTFORM = "CONTACTFORM";
export const PACKAGELIST = "PACKAGELIST";
export const PACKAGEDETAIL = "PACKAGEDETAIL";
export const CRUISEDETAIL = "CRUISEDETAIL";
export const BLOGDETAIL = "BLOGDETAIL";
export const JOBS = "JOBS";
export const SEARCH = "SEARCH";
export const CLEAR = "CLEAR";