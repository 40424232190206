import React from "react";
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import reduxStore from "./redux/store";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import { hydrate, render } from "react-dom";

const { store, persistor } = reduxStore();

// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <PersistGate loading={null} persistor={persistor}>
//         <App />
//       </PersistGate>
//     </BrowserRouter>
//   </Provider>
// )

const rootElement = document.getElementById("root");
const app = (
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </BrowserRouter>
  </Provider>
);

render(app , rootElement);

if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}

reportWebVitals();
